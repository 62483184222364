export default {
  title: 'Tipo de campo',
  plural: 'Tipo de campo',
  upper: 'TIPOS_DE_CAMPO',
  upperPlural: 'TIPOS_DE_CAMPOS',
  lower: 'tipo de campo',
  lowerPlural: 'tipo de campos',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    label: {
      label: "Nome da tipo de campo",
      key: "label",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    description: {
      label: "Descrição",
      key: "descrição",
    },
    configs: {
      label: "Configuração",
      key: "configs",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados das tipo de campos.",
  erroLoadTrash: "Erro ao carregar dados das tipo de campos  para lixeira.",
  itemRemovidoLixeira: "Disciolina movida pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover tipo de campo para a lixeira.",
  itemExcuidoLixeira: "Tipo de campos excluída da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir tipo de campo da lixeira.",
  itemRestauradoLixeira: "Tipo de campos restaurada com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar tipo de campo.",
  createTitle: "Nova tipo de campo",
  create: "Tipo de campos criada com sucesso.",
  update: "Tipo de campos editada com sucesso.",
  updateTitle: "Editar tipo de campo",
  createError: "Erro ao criar a tipo de campo.",
  updateError: "Erro ao editar a tipo de campo.",
  inputs: {
    id: null,
    label: null,
    identifier: null,
    description: null,
    configs: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da tipo de campo",
      example: "1",
    },
    label: {
      label: "Nome da tipo de campo",
      description: "Nome da tipo de campo",
      example: "Matemática",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador da tipo de campo",
      example: "matematica",
    },
    description: {
      label: "Descrição",
      description: "Descrição do tipo de ",
      example: "MAT",
    },
    configs: {
      label: "Configuração",
      description: "Configuração do tipo de campo",
      example: "Select",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação da tipo de campo",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da tipo de campo",
      example: "2021-01-01 00:00:00",
    }
  }
}

import axios from "axios";

const REQUEST =
  `${process.env.VUE_APP_DOMAIN}/core_usuarios/api`;

const getToken = () => {
  const user = JSON.parse(localStorage.getItem("auth.currentUser"));
  const token =
    !!user && !!user.stsTokenManager ? user.stsTokenManager.accessToken : "";

  return token;
};

export const fetchUsers = async ({ search = "", page = 1, perPage = 10 }) => {
  const queryString = search !== "" ? `?search=${search}&` : "?";

  try {
    const { data } = await axios({
      headers: {
        Authorization: getToken(),
      },
      baseURL: REQUEST,
      method: "get",
      url: `/index${queryString}page=${page}&per_page=${perPage}`,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const saveUser = async (payload) => {
  try {
    const data = await axios({
      headers: {
        Authorization: getToken(),
      },
      baseURL: REQUEST,
      method: !!payload.uid ? "put" : "post",
      url: `/index`,
      data: payload,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const deleteUser = async (id) => {
  try {
    const { data } = await axios({
      headers: {
        Authorization: getToken(),
      },
      baseURL: REQUEST,
      method: "delete",
      url: `/index/${id}`,
    });

    return data;
  } catch (error) {
    return error;
  }
};

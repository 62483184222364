import Axios from "axios";
import { recieverDTO, senderDTO } from "../utils/DTOS";
import { save } from "../../../peoples/peoples_extras/services/services";
import { error } from "jquery";

const REQUEST =
  `${process.env.VUE_APP_DOMAIN}/v1_schools_accounts/v1_schools_accounts/v1`;

const axiosInstance = Axios.create({
  baseURL: REQUEST,
});

export const fetchSchool = async (idSchool) => {
  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `/${idSchool}/school`,
    });

    return recieverDTO(data, idSchool);
  } catch (error) {
    return error;
  }
};

export const saveSchool = async (idSchool, payload) => {
  try {
    const [newPayload] = senderDTO(payload);
    const { peoples_extras } = newPayload;
    let data;

    delete newPayload.peoples_extras;

    for (let x of peoples_extras) {
      try {
        data = await save(x);
      } catch (error) {
        throw error;
      }
    }

    data = await axiosInstance({
      method: "post",
      url: `/${idSchool}/school`,
      data: { ...newPayload },
    });

    return data;
  } catch (error) {
    return error;
  }
};

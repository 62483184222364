import { fetchSchool, saveSchool } from "../services/services.js";
import { mountMessage } from "@/utils/MountMessage";

const state = {
  school: {},
  loading: { status: "idle" },
};

const getters = {
  school(state) {
    return state.school;
  },
  isLoading(state) {
    return state.loading;
  },
};

const mutations = {
  SET_SCHOOL(state, payload) {
    state.school = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async getSchool({ commit }) {
    commit("SET_LOADING", { status: "loading" });
    const idSchool = localStorage.getItem("id_school") || 4;

    try {
      const response = await fetchSchool(idSchool);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_SCHOOL", response);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async save({ commit }, payload) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await saveSchool(payload.id, payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

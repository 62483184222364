import physicalRoomsTypesRoute from '@/modules/physical-rooms-types/physical-rooms-types-route';
import physicalRoomsResourcesRoute from '@/modules/physical-rooms-resources/physical-rooms-resources-route';
import physicalRoomsRoute from '@/modules/physical-rooms/physical-rooms-route';
import knowledgeAreasRoute from '@/modules/knowledge-areas/knowledge-areas-route';
import teachingsRoute from '@/modules/teachings/teachings-route';
import levelsRoute from '@/modules/levels/levels-route';
import coursesRoute from '@/modules/courses/courses-route';
import periodsRoute from '@/modules/periods/periods-route';
import itinerariesRoute from '@/modules/itineraries/itineraries-route';
import curriculumsRoute from '@/modules/curriculums/curriculums-route';
import classesRoute from '@/modules/classes/classes-route';
import trailsRoute from '@/modules/trails/trails-route';
import classesTrailsRoute from '@/modules/classes-trails/classes-trails-route';
import levelsCapacityPerYearsRoute from '@/modules/levels-capacity-per-years/levels-capacity-per-years-route';
import schoolEnrollmentsRoute from '@/modules/schools-enrollments/schools-enrollments-route';
import DisciplinesRoute from "@/modules/disciplines/disciplines-route";
import HolidaysRoute from "@/modules/holidays/holidays-route";
import PipelinesRoute from "@/modules/pipelines/pipelines-route";
import StagesRoute from "@/modules/stages/stages-route";
import CardsRoute from "@/modules/cards/cards-route";
import CardsRouteView from "@/modules/cards/cards-route-view";
import CardsRolesRoute from "@/modules/cards-roles/cards-roles-route";
import CardsTypesRoute from "@/modules/cards-types/cards-types-route";
import CardsLevelsRoute from "@/modules/cards-levels/cards-levels-route";
import CardsFilesRoute from "@/modules/cards-files/cards-files-route";
import CardsCommentsRoute from "@/modules/cards-comments/cards-comments-route";
import CardsLogsRoute from "@/modules/cards-logs/cards-logs-route";
import StagesStatusRoute from "@/modules/stages-status/stages-status-route";
import PeriodicModelsRoute from "@/modules/periodic-models/periodic-models-route";
import PeriodicTypesRoute from "@/modules/periodic-types/periodic-types-route";
import VideosRoute from "@/modules/videos/videos-route";
import FilesRoute from "@/modules/files/files-route";
import ExercisesRoute from "@/modules/exercises/exercises-route";
import ClassesViewRoute from "@/modules/classes/classes-view-route";
import PayProducts from "@/modules/pay-products/pay-products-route"
import PayMethods from "@/modules/pay-methods/pay-methods-route";
import PayInvoicesDocumentsFiscal from "@/modules/pay-invoices-documents-fiscal/pay-invoices-documents-fiscal-route";
import PayStatus from "@/modules/pay-status/pay-status-route"
import PayInvoices from "@/modules/pay-invoices/pay-invoices-route";
import forms from "@/modules/forms/forms-route";
import PeoplesSpecialtiesRoute from "@/modules/peoples-specialties/peoples-specialties-route";
import PeoplesSpecialtiesTypesRoute from "@/modules/peoples-specialties-types/peoples-specialties-types-route";
import CalendarsTypesRoute from "@/modules/calendars-types/calendars-types-route";
import CalendarsRoute from "@/modules/calendars/calendars-route";
import years from "@/modules/years/years-route";
import months from "@/modules/months/months-route";
import weeks from "@/modules/weeks/weeks-route";
import daysOfWeeks from "@/modules/days-of-weeks/days-of-weeks-route";
import PostsConfigsRoute from "@/modules/posts-configs/posts-configs-route";
import PayProductsTypes from "@/modules/pay-products-types/pay-products-types-route";
import rules from "@/modules/rules/rules-route";
import permissions from "@/modules/permissions/permissions-route";
import policies from "@/modules/policies/policies-route";
import assessments_types from "@/modules/assessments_types/assessments_types-route";
import schoolsTypes from "@/modules/schools-types/schools-types-route";
import profiles from "@/modules/profiles/profiles-route"

export default [
    PostsConfigsRoute,
    schoolsTypes,
    PayProducts,
    forms,
    CalendarsTypesRoute,
    CalendarsRoute,
    PeoplesSpecialtiesRoute,
    PeoplesSpecialtiesTypesRoute,
    PayMethods,
    PayStatus,
    PayInvoices,
    PayInvoicesDocumentsFiscal,
    ClassesViewRoute,
    PeriodicModelsRoute,
    PeriodicTypesRoute,
    VideosRoute,
    FilesRoute,
    ExercisesRoute,
    HolidaysRoute,
    DisciplinesRoute,
    physicalRoomsTypesRoute,
    CardsRouteView,
    physicalRoomsResourcesRoute,
    physicalRoomsRoute,
    knowledgeAreasRoute,
    teachingsRoute,
    levelsRoute,
    coursesRoute,
    periodsRoute,
    itinerariesRoute,
    curriculumsRoute,
    classesRoute,
    trailsRoute,
    classesTrailsRoute,
    levelsCapacityPerYearsRoute,
    schoolEnrollmentsRoute,
    PipelinesRoute,
    StagesRoute,
    CardsRoute,
    CardsRolesRoute,
    CardsTypesRoute,
    CardsLevelsRoute,
    CardsFilesRoute,
    CardsCommentsRoute,
    CardsLogsRoute,
    StagesStatusRoute,
    years,
    months,
    weeks,
    daysOfWeeks,
    PayProductsTypes,
    rules,
    permissions,
    profiles,
    policies,
    assessments_types,
]

export default {
    title: 'Período',
    plural: 'Períodos',
    upper: 'PERÍODO',
    upperPlural: 'PERÍODOS',
    lower: 'período',
    lowerPlural: 'períodos',
    headers: {
        id: {
            label: "ID",
            key: "id",
        },
        identifier: {
            label: "Identificador",
            key: "identifier",
        },
        label: {
            label: "Nome do período",
            key: "label",
        },
        date_start: {
            label: "Data de início",
            key: "date_start",
        },
        date_end: {
            label: "Data de fim",
            key: "date_end",
        },
        time_start: {
            label: "Hora de início",
            key: "time_start",
        },
        time_end: {
            label: "Hora de fim",
            key: "time_end",
        },
        created_at: {
            label: "Data de criação",
            key: "created_at",
        },
        updated_at: {
            label: "Última atualização",
            key: "updated_at",
        },

        order: {
            label: "Ordem",
            key: "order",
        },
        year: {
            label: "Ano",
            key: "year",
        },
        periodic_models: {
            label: "Modelo de período",
            key: "title",
        },
    },
    erroLoadPaginate: "Erro ao carregar dados do período.",
    erroLoadTrash: "Erro ao carregar período da lixeira.",
    itemRemovidoLixeira: "Período movido pra lixeira com sucesso.",
    itemRemovidoLixeiraErro: "Erro ao remover o período para a lixeira.",
    itemExcuidoLixeira: "Período excluído da lixeira com sucesso.",
    itemExcuidoLixeiraErro: "Erro ao excluir o período da lixeira.",
    itemRestauradoLixeira: "Período restaurado com sucesso.",
    itemRestauradoLixeiraErro: "Erro ao restaurar o período.",
    createTitle: "Novo período",
    create: "Periodos criada com sucesso.",
    update: "Periodos editada com sucesso.",
    updateTitle: "Editar períodos",
    createError: "Erro ao criar  período.",
    updateError: "Erro ao editar período.",
    inputs: {id: null, label: "", identifier: "", time_start: "", time_end: "", date_end: "", date_start: "", id_period_model: null, order: null, year: null},
    inputsInfos: {
        id: {
            label: "ID",
            description: "ID da período",
            example: "1",
        },
        id_period_model: {
            label: "Modelo de período",
            description: "Modelo de período",
            example: "1",
        },
        order: {
            label: "Ordem",
            description: "Ordem do período",
            example: "1",
        },
        year: {
            label: "Ano",
            description: "Ano do período",
            example: "2024",
        },
        label: {
            label: "Nome da período",
            description: "Nome da período",
            example: "Manhã",
        },
        identifier: {
            label: "Identificador",
            description: "Identificador da período",
            example: "manhã",
        },
        time_start: {
            label: "Hora de início",
            description: "Hora de início do período",
            example: "08:00:00",
        },
        time_end: {
            label: "Hora de fim",
            description: "Hora de fim da período",
            example: "12:00:00",
        },
        date_start: {
            label: "Data de início no período",
            description: "Data de início do período",
            example: "01/01/2024",
        },
        date_end: {
            label: "Data de fim",
            description: "Data de fim da período",
            example: "01/12/2024",
        },
        created_at: {
            label: "Data de criação",
            description: "Data de criação do período",
            example: "2021-01-01 00:00:00",
        },
        updated_at: {
            label: "Última atualização",
            description: "Data da última atualização do período",
            example: "2021-01-01 00:00:00",
        }
    }
}

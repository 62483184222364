const labels = {
  title: 'Politica',
  plural: 'Politicas',
  upper: 'POLITICA',
  upperPlural: 'POLITICAS',
  lower: 'politica',
  lowerPlural: 'politicas',
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    label: {
      label: "Nome",
      key: "name",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    label: null,
    identifier: null,
    rules: [],
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID da ${labels.lower}`,
      example: "1",
    },
    label: {
      label: `Nome da ${labels.lower}`,
      description: `Nome da ${labels.lower}`,
      example: "permissão de administração",
    },
    identifier: {
      label: "Identificador",
      description: `Identificador da ${labels.lower}`,
      example: "admin",
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação da ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização da ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}

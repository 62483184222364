<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
const LayoutHorizontal = () => import("@/layouts/HorizontalLayout.vue");
const LayoutFull = () => import("@/layouts/FullLayout.vue");

export default {
  components: {
    LayoutHorizontal,
    LayoutFull,
  },
  computed: {
    layout() {
      return this.$route.meta.layout === "full"
        ? "layout-full"
        : "layout-horizontal";
    },
  },
  mounted() {
    // Width
    try{
        if (this.$store.state.themeConfig.contentWidth == "full") {
            document.querySelector("body").classList.remove("content-width-boxed");
        }
    }catch (e){}

   try{
       if (this.$store.state.themeConfig.contentWidth == "boxed") {
           document.querySelector("body").classList.add("content-width-boxed");
       }
   }catch (e){}

    // Layout
   try{
       if (this.$store.state.themeConfig.sidebarCollapsed) {
           document.body.classList.add("collapsed-active");
           document.body.classList.remove("header-full");
           document.body.classList.remove("app-layout-bg");
       }

       if (this.$store.state.themeConfig.navigationFull) {
           document.body.classList.add("header-full");
           document.body.classList.remove("app-layout-bg");
           document.body.classList.remove("collapsed-active");
       }

       if (this.$store.state.themeConfig.navigationBg) {
           document.body.classList.add("header-full");
           document.body.classList.add("app-layout-bg");
           document.body.classList.remove("collapsed-active");
       }
   }catch (e){}
  },
};
</script>

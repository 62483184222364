export default {
  title: 'Agrupamento de Itinerário',
  plural: 'Agrupamentos de Itinerários',
  upper: 'AGRUPAMENTO DE ITINERÁRIO',
  upperPlural: 'AGRUPAMENTOS DE ITINERÁRIOS',
  lower: 'agrupamento de itinerário',
  lowerPlural: 'agrupamentos de itinerário',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    label: {
      label: "Nome do itinerário",
      key: "label",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    acronym: {
      label: "Sigla",
      key: "acronym",
    },
    mandatory: {
      label: "Obrigatório",
      key: "mandatory",
      options: [
        {value: 'YES', text: "Sim"},
        {value: 'NO', text: "Não"},
        {value: 'FLEXIBLE', text: "Flexível"},
      ]
    },
    code: {
      label: "Código",
      key: "code",
    },
    order: {
      label: "Ordem",
      key: "order",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados dos itinerários.",
  erroLoadTrash: "Erro ao carregar dados dos itinerários das lixeira.",
  itemRemovidoLixeira: "Itinerário movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover o itinerário para a lixeira.",
  itemExcuidoLixeira: "Itinerário excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir o itinerário da lixeira.",
  itemRestauradoLixeira: "Itinerário restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar o itinerário.",
  createTitle: "Novo itinerário",
  create: "Itinerário criado com sucesso.",
  update: "Itinerário editado com sucesso.",
  updateTitle: "Editar Itinerário",
  createError: "Erro ao criar a itinerário.",
  updateError: "Erro ao editar a itinerário.",
  inputs: {
    id: null,
    identifier: null,
    label: null,
    acronym: null,
    description: null,
    mandatory: null,
    code: null,
    order: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID do itinerário",
      example: "1",
    },
    label: {
      label: "Nome do itinerário",
      description: "Nome do itinerário",
      example: "Itinerário",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador do itinerário",
      example: "Itinerário",
    },
    acronym: {
      label: "Sigla",
      description: "Sigla do itinerário",
      example: "IT",
    },
    description: {
      label: "Descrição",
      description: "Descrição do itinerário",
      example: "Novo Itinerário",
    },
    mandatory: {
      label: "Obrigatório",
      description: "Itinerário obrigatório",
      example: "Sim, Não, Flexível",
      options: [
        {value: 'YES', text: "Sim"},
        {value: 'NO', text: "Não"},
        {value: 'FLEXIBLE', text: "Flexível"},
      ]
    },
    code: {
      label: "Código",
      description: "Código do itinerário",
      example: "1",
    },
    order: {
      label: "Ordem",
      description: "Ordem do itinerário",
      example: "1",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação do itinerário",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização do itinerário",
      example: "2021-01-01 00:00:00",
    }
  }
}

import { FIELDS } from "./constants";

const hourFormatter = (date) => {
  let hour = date.getHours();
  let min = date.getMinutes();

  hour = hour < 10 ? "0" + hour : hour;
  min = min < 10 ? "0" + min : min;

  return hour + ":" + min;
};

export const recieverDTO = (data, id_school) => {
  if (!data) return [];

  let [newData] = data
    .filter(({ id }) => id === id_school)
    .map((el) => {
      localStorage.setItem("originalSchool", JSON.stringify(el));
      let newPeoplesExtras = {};

      FIELDS.forEach((field) => {
        const { value } =
          el.peoples.peoples_extras.filter(
            (item) => field.id === item.id_people_extra_type && item.value
          )[0] || "";

        newPeoplesExtras[`${field.identifier}`] = value;
      });

      let newOpeningHours = el.opening_hours.map((el) => {
        let start = hourFormatter(new Date(el.start));
        let end = hourFormatter(new Date(el.end));

        return {
          ...el,
          start,
          end,
        };
      });

      return {
        ...el,
        opening_hours: [...newOpeningHours],
        peoples: {
          ...el.peoples,
          peoples_extras: { ...newPeoplesExtras },
          peoples_address: el.peoples.peoples_address[0],
        },
      };
    });

  return newData;
};

export const senderDTO = (data) => {
  let payload = JSON.parse(localStorage.getItem("originalSchool"));

  payload = [payload].map((el) => {
    let newPeoplesExtras = el.peoples.peoples_extras.map((item) => {
      const extra = FIELDS.find(({ id }) => id === item.id_people_extra_type);

      if (!!extra)
        return {
          id: item.id || null,
          id_people: data.id_people,
          id_people_extra_type: extra.id,
          value: data.peoples.peoples_extras[extra.identifier] || "",
          version: new Date().toISOString().slice(0, 10),
        };
    });

    return {
      id: el.id,
      schools: {
        id_people: data.id_people,
        id_people_admin: data.id_people_admin,
        id_people_model: data.id_people_model,
        id_school_type: data.id_school_type,
        id_periodic_model: data.id_periodic_model,
      },
      peoples_telephones: data.peoples.peoples_telephones[0],
      peoples: {
        id: data.id,
        id_people_type: data.peoples.id_people_type,
        id_people_race: data.peoples.id_people_race,
        name: data.peoples.name,
        document: data.peoples.document,
        sex: data.peoples.sex,
        photo: data.peoples.photo,
        date_of_birth: data.peoples.date_of_birth,
      },
      peoples_extras: [...newPeoplesExtras],
      peoples_extras_types: {},
      peoples_address: data.peoples.peoples_address,
    };
  });

  return payload;
};

import Axios from "axios";

const REQUEST =
  `${process.env.VUE_APP_DOMAIN}/v1_peoples/v1/1/people/extra`;

const axiosInstance = Axios.create({
  baseURL: REQUEST,
});

export const save = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: payload.id ? "put" : "post",
      data: { ...payload },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const FIELDS = [
  {
    id: 11,
    identifier: "fantasy_name",
  },
  {
    id: 12,
    identifier: "school_name",
  },
  {
    id: 13,
    identifier: "email",
  },
  {
    id: 14,
    identifier: "site",
  },
  {
    id: 15,
    identifier: "domain",
  },
  {
    id: 16,
    identifier: "primary_color",
  },
  {
    id: 17,
    identifier: "secondary_color",
  },
  {
    id: 18,
    identifier: "terciary_color",
  },
  {
    id: 19,
    identifier: "site_title",
  },
  {
    id: 20,
    identifier: "site_description",
  },
  {
    id: 21,
    identifier: "port",
  },
  {
    id: 22,
    identifier: "server",
  },
  {
    id: 23,
    identifier: "user",
  },
  {
    id: 24,
    identifier: "password",
  },
  {
    id: 25,
    identifier: "reciever",
  },
  {
    id: 26,
    identifier: "answer_to",
  },
  {
    id: 27,
    identifier: "copy",
  },
  {
    id: 28,
    identifier: "hidden_copy",
  },
];

import {
  saveUser as saveUserService,
  fetchUsers,
  deleteUser as deleteUserService,
} from "../services/services";
import { mountMessage } from "../../../../utils/MountMessage";

const state = {
  users: [],
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  deleteLoading: { status: "idle" },
};

const getters = {
  users(state) {
    return state.users;
  },
  usersToOptions(state) {
    const options = state.users.map((el) => {
      return {
        text: el.displayName,
        value: el.uid,
      };
    });
    
    return options;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isDeleteLoading(state) {
    return state.deleteLoading;
  },
};

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_DELETE_LOADING(state, payload) {
    state.deleteLoading = payload;
  },

  SET_USERS_PAGINATION(state, payload) {
    state.pagination = payload;
  },
};

const actions = {
  async getUsers({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchUsers(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_USERS", response.users);
        commit("SET_USERS_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async saveUser({ commit, dispatch }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveUserService(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getUsers", {});
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async deleteUser({ commit, dispatch }, id) {
    commit("SET_DELETE_LOADING", { status: "loading" });
    try {
      const response = await deleteUserService(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getUsers", {});
        commit("SET_DELETE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_DELETE_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
